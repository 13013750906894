import { workshopApi } from '@/workshop/workshop-api.js'
import { attendeeSpecialtyOptions } from '@/workshop/consts/attendee-specialty-options.js'

const getDefaultState = () => {
  return {
    userProfileForm: {
      id: null,
      email: null,
      firstName: null,
      lastName: null,
      primaryPhone: null,
      hospital: {
        hospitalAffiliation: null,
        streetAddress: null,
        secondaryAddress: null,
        city: null,
        state: null,
        zipCode: null,
        country: null,
        otherCountry: null
      },
      currentStatus: null,
      cellPhone: null,
      otherSpecialty: null,
      specialties: []
    }
  }
}

const state = getDefaultState()

const getters = {
  userProfileForm (state) {
    return state.userProfileForm
  }
}

const actions = {
  async fetchFacultyProfileForm ({ commit }) {
    const res = await workshopApi.get('/faculty/profile/fetch')
    const facultyProfileInfo = res.data
    setBaseProfileInfo(facultyProfileInfo, commit)
  },
  async fetchAttendeeProfileForm ({ commit }) {
    const res = await workshopApi.get('/attendee/profile/fetch')
    const attendeeProfileInfo = res.data
    setAttendeeOnlyProfileInfo(attendeeProfileInfo, commit)
    setBaseProfileInfo(attendeeProfileInfo, commit)
  },
  async updateAttendeeProfileForm ({ state }) {
    const payload = getAttendeeProfileFormPayload(state.userProfileForm)
    await workshopApi.put('/attendee/profile/update', payload)
  },
  async updateFacultyProfileForm ({ state }) {
    const payload = getBaseProfileFormPayload(state.userProfileForm)
    await workshopApi.put('/faculty/profile/update', payload)
  }
}

const mutations = {
  SET_PROFILE_ID (state, id) {
    state.userProfileForm.id = id
  },
  SET_PROFILE_FIRST_NAME (state, firstName) {
    state.userProfileForm.firstName = firstName
  },
  SET_PROFILE_LAST_NAME (state, lastName) {
    state.userProfileForm.lastName = lastName
  },
  SET_PROFILE_CURRENT_STATUS (state, currentStatus) {
    state.userProfileForm.currentStatus = currentStatus
  },
  SET_PROFILE_PRIMARY_PHONE (state, primaryPhone) {
    state.userProfileForm.primaryPhone = primaryPhone
  },
  SET_PROFILE_CELL_PHONE (state, cellPhone) {
    state.userProfileForm.cellPhone = cellPhone
  },
  SET_PROFILE_EMAIL (state, email) {
    state.userProfileForm.email = email
  },
  SET_PROFILE_HOSPITAL_AFFILIATION (state, hospitalAffiliation) {
    state.userProfileForm.hospital.hospitalAffiliation = hospitalAffiliation
  },
  SET_PROFILE_HOSPITAL_STREET_ADDRESS (state, hospitalStreetAddress) {
    state.userProfileForm.hospital.streetAddress = hospitalStreetAddress
  },
  SET_PROFILE_HOSPITAL_SECONDARY_ADDRESS (state, hospitalSecondaryAddress) {
    state.userProfileForm.hospital.secondaryAddress = hospitalSecondaryAddress
  },
  SET_PROFILE_HOSPITAL_ZIP_CODE (state, hospitalZipCode) {
    state.userProfileForm.hospital.zipCode = hospitalZipCode
  },
  SET_PROFILE_HOSPITAL_CITY (state, hospitalCity) {
    state.userProfileForm.hospital.city = hospitalCity
  },
  SET_PROFILE_HOSPITAL_COUNTRY (state, hospitalCountry) {
    state.userProfileForm.hospital.country = hospitalCountry
  },
  SET_PROFILE_HOSPITAL_OTHER_COUNTRY (state, otherCountry) {
    state.userProfileForm.hospital.otherCountry = otherCountry
  },
  SET_PROFILE_HOSPITAL_STATE (state, hospitalState) {
    state.userProfileForm.hospital.state = hospitalState
  },
  SET_PROFILE_OTHER_SPECIALTY (state, otherSpecialty) {
    state.userProfileForm.otherSpecialty = otherSpecialty
  },
  SET_PROFILE_SPECIALTIES (state, specialties) {
    state.userProfileForm.specialties = specialties
  },
  CLEAR_WORKSHOP_PROFILE_STATE (state) {
    Object.assign(state, getDefaultState())
  }
}

function setBaseProfileInfo (baseProfileInfo, commit) {
  commit('SET_PROFILE_ID', baseProfileInfo.id)
  commit('SET_PROFILE_FIRST_NAME', baseProfileInfo.firstName)
  commit('SET_PROFILE_LAST_NAME', baseProfileInfo.lastName)
  commit('SET_PROFILE_PRIMARY_PHONE', baseProfileInfo.primaryPhone)
  commit('SET_PROFILE_EMAIL', baseProfileInfo.email)
  commit('SET_PROFILE_HOSPITAL_AFFILIATION', baseProfileInfo.hospital.hospitalAffiliation)
  commit('SET_PROFILE_HOSPITAL_CITY', baseProfileInfo.hospital.city)
  commit('SET_PROFILE_HOSPITAL_STREET_ADDRESS', baseProfileInfo.hospital.streetAddress)
  commit('SET_PROFILE_HOSPITAL_SECONDARY_ADDRESS', baseProfileInfo.hospital.secondaryAddress)
  commit('SET_PROFILE_HOSPITAL_ZIP_CODE', baseProfileInfo.hospital.zipCode)

  if (baseProfileInfo.hospital.country === 'USA') {
    commit('SET_PROFILE_HOSPITAL_STATE', baseProfileInfo.hospital.state)
    commit('SET_PROFILE_HOSPITAL_COUNTRY', 'USA')
  } else {
    commit('SET_PROFILE_HOSPITAL_OTHER_COUNTRY', baseProfileInfo.hospital.country)
    commit('SET_PROFILE_HOSPITAL_COUNTRY', 'Other')
  }
}

function setAttendeeOnlyProfileInfo (attendeeProfileInfo, commit) {
  commit('SET_PROFILE_CURRENT_STATUS', attendeeProfileInfo.currentMedicalCredentialStatus)
  commit('SET_PROFILE_CELL_PHONE', attendeeProfileInfo.cellPhone)
  const otherSpecialty = attendeeProfileInfo.specialties.find(
    specialty => !attendeeSpecialtyOptions.includes(specialty)
  )
  if (otherSpecialty) {
    attendeeProfileInfo.specialties.push('Other')
    commit('SET_PROFILE_SPECIALTIES', attendeeProfileInfo.specialties)
  } else {
    commit('SET_PROFILE_SPECIALTIES', attendeeProfileInfo.specialties)
  }
  commit('SET_PROFILE_OTHER_SPECIALTY', otherSpecialty)
}

function getAttendeeProfileFormPayload (attendeeProfileForm) {
  return {
    ...getBaseProfileFormPayload(attendeeProfileForm),
    currentMedicalCredentialStatus: attendeeProfileForm.currentStatus,
    cellPhone: attendeeProfileForm.cellPhone || null,
    otherSpecialty: attendeeProfileForm.otherSpecialty,
    specialties: attendeeProfileForm.specialties.filter(specialty => specialty !== 'Other')
  }
}

function getBaseProfileFormPayload (baseProfileForm) {
  return {
    id: baseProfileForm.id,
    email: baseProfileForm.email,
    firstName: baseProfileForm.firstName,
    lastName: baseProfileForm.lastName,
    primaryPhone: baseProfileForm.primaryPhone,
    hospital: {
      hospitalAffiliation: baseProfileForm.hospital.hospitalAffiliation,
      streetAddress: baseProfileForm.hospital.streetAddress,
      secondaryAddress: baseProfileForm.hospital.secondaryAddress || null,
      city: baseProfileForm.hospital.city,
      state: baseProfileForm.hospital.state || null,
      zipCode: baseProfileForm.hospital.zipCode,
      country: baseProfileForm.hospital.country === 'Other' ? baseProfileForm.hospital.otherCountry : 'USA'
    }
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
