<template>
  <BaseModal
    v-model="show"
    v-bind="props"
    content-class="msg-box-ok"
    data-testid="msgbox-ok"
    @clickOutside="confirm"
  >
    <BaseCard>
      <div class="card-x-button">
        <BaseButtonIcon
          v-if="!confirmText"
          ref="confirmBtn"
          data-cy="alert-close"
          icon="notification-card-x.svg"
          data-testid="alert-confirm-ok"
          @click="confirm"
        />
      </div>
      <div class="notif-container">
        <div v-if="alertIcon" class="msg-box-ok-img" data-testid="base-alert-img">
          <v-img :src="alertIcon" height="50"></v-img>
        </div>
        <BaseCardTitle
          v-if="title"
          data-cy="base-alert-title-text"
          data-testid="base-alert-title-text"
          class="msg-box-ok-title"
        >
          <h4 class="centered-alert-text" :style="titleStyle">{{ title }}</h4>
        </BaseCardTitle>
        <BaseCardText v-if="text" class="msg-box-ok-text alertText" data-testid="base-alert-text">
          <!-- eslint-disable-next-line vue/no-v-html -->
          <span v-html="text" class="msg-box-ok-light-text centered-alert-text" :style="textStyle"></span>
        </BaseCardText>
        <div v-else class="msg-box-ok-empty-text"></div>
        <div class="msg-box-ok-btn-container">
          <BaseButton
            v-if="confirmText"
            colorScheme="light-teal"
            data-cy="alert-confirm"
            data-testid="alert-confirm-ok"
            :style="confirmStyle"
            @click="confirm"
          >
            {{ confirmText }}
          </BaseButton>
        </div>
        <div class="msg-box-ok-spacer"></div>
      </div>
    </BaseCard>
  </BaseModal>
</template>

<script>
import alertTypes from './alert-types'
import BaseButtonIcon from '@/components/buttons/BaseButtonIcon'
import BaseButton from '@/components/buttons/BaseButton'
import BaseModal from '@/components/BaseModal'
import BaseCard from '@/components/BaseCard'
import BaseCardTitle from '@/components/BaseCardTitle'
import BaseCardText from '@/components/BaseCardText'

export default {
  name: 'MsgBoxOk',
  components: { BaseButtonIcon, BaseButton, BaseModal, BaseCard, BaseCardTitle, BaseCardText },
  props: {
    modelValue: {
      type: Boolean,
      required: false,
      default: true
    },
    title: {
      type: String,
      required: false
    },
    titleStyle: {
      type: Object,
      required: false
    },
    text: {
      type: String,
      required: false
    },
    textStyle: {
      type: Object,
      required: false
    },
    alertType: {
      type: String,
      required: false,
      validator: (type) => [...Object.values(alertTypes)].indexOf(type) !== -1
    },
    vDialogProps: {
      type: Object,
      required: false
    },
    width: {
      type: Number,
      required: false,
      default: 432
    },
    imgSrc: {
      type: String,
      required: false
    },
    // Instance of mitt passed into us from base-alert-modal.js
    emitter: {
      type: Object,
      required: true
    },
    confirmText: {
      type: String,
      required: false,
      default: ''
    },
    /**
     * Customize the style of the confirm button
     * Each key-value pair needs to end with a semicolon
     *
     * @param { 'css-property': 'css-value'; }
     * @example
     * { 'margin-left': 'auto'; }
     */
    confirmStyle: {
      type: Object,
      required: false,
      default: () => {}
    }
  },
  data () {
    return {
      show: this.modelValue
    }
  },
  computed: {
    alertIcon () {
      switch (this.alertType) {
        case alertTypes.SUCCESS:
          return require('@/assets/icons/alert-success-large.svg')
        case alertTypes.WARN:
          return require('@/assets/icons/alert-warn-large.svg')
        case alertTypes.ERROR:
          return require('@/assets/icons/alert-error-large.svg')
        case alertTypes.CUSTOM:
          return require(`@/assets/icons/${this.imgSrc}`)
        case alertTypes.NONE:
        default:
          return '' // renders no icon
      }
    },
    // The props which to pass along to <v-dialog>
    props () {
      const defaultProps = {
        'persistent': false,
        'width': this.width,
        'origin': 'top'
      }
      return { ...defaultProps, ...this.vDialogProps }
    }
  },
  watch: {
    $route (to, from) {
      // close on any route change
      this.emitter.emit('confirm')
    }
  },
  methods: {
    confirm () {
      this.show = false
      this.emitter.emit('confirm')
    }
  }
}
</script>

<style lang="scss">
// This style is not scoped because this styling is not being applied when scoped.
// Likely due to being instantiated with createApp()
@import "@/components/alert/msg-alert-mixin.scss";

// Change position of the modal from center to top
.msg-box-ok {
  top: -45%;
}

.msg-box-ok-title {
  @include alert-msg-title;

  font-size: 18px;
  padding-top: 12px !important;
}

.msg-box-ok-title h4 {
  @include alert-msg-title-h4;

  padding-bottom: 8px !important;
  line-height: 26px !important;
}

.msg-box-ok-text {
  @include alert-msg-text;

  padding-top: 16px !important;
  padding-bottom: 20px !important;
  font-size: 16px;
}

.notif-container {
  padding-top: 10px;
}

.centered-alert-text {
  width: 100%;
  text-align: center;
  margin: auto;
  word-break: keep-all;
}

.msg-box-ok-light-text {
  color: $neutral-typography-medium !important;
}

.msg-box-ok-spacer {
  width: 100%;
  min-height: 28px;
}

.msg-box-ok-img {
  width: 100%;
  display: flex;
  margin-top: 2.5%;
  justify-content: center;
  align-items: center;
}

.card-x-button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 12px;
  padding-right: 14px;
}

.msg-box-ok-empty-text {
  margin-top: 20px;
}

.msg-box-ok-btn-container {
  display: flex;
  justify-content: center;
}
</style>
