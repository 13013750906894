import { workshopApi } from '@/workshop/workshop-api'
import workshopRoles from '@/workshop/consts/workshop-roles'

const defaultState = () => ({
  tokenInfo: {
    accessToken: '',
    refreshToken: '',
    accessTokenDuration: 0,
    accessTokenRetrievedDate: new Date()
  },
  me: {
    id: '',
    email: '',
    firstName: '',
    lastName: '',
    fullName: '',
    role: '',
    isFaculty: false,
    isAttendee: false
  }
})

const state = defaultState()

const getters = {
  isAuthenticated: state => !!state.tokenInfo.refreshToken,
  me: state => state.me,
  getTokenInfo: state => state.tokenInfo,
  isAdmin: state => {
    return state.me.role === workshopRoles.WORKSHOP_ADMIN.value
  },
  isAdministrativeRole: state => {
    return state.me.role === workshopRoles.WORKSHOP_ADMIN.value ||
      state.me.role === workshopRoles.CLINICAL_EDUCATION.value ||
      state.me.role === workshopRoles.FACILITATOR.value
  },
  isManagerialRole: state => {
    return state.me.role === workshopRoles.WORKSHOP_ADMIN.value ||
      state.me.role === workshopRoles.CLINICAL_EDUCATION.value
  },
  isParticipant: state => state.me.role === workshopRoles.PARTICIPANT.value,
  isFaculty: state => state.me.isFaculty,
  isPureFaculty: state => state.me.isFaculty && !state.me.isAttendee,
  isAttendee: state => state.me.isAttendee,
  isPureAttendee: state => state.me.isAttendee && !state.me.isFaculty
}

const actions = {
  login: async ({ commit, getters, dispatch }, authToken) => {
    commit('SAVE_NEW_AUTH_TOKEN', authToken)
    const resp = await workshopApi.get('/me')
    commit('SET_ME', resp.data)
    if (!getters.isAdministrativeRole) {
      await dispatch('getProfilePhoto')
    } else {
      // fetch upcoming and past workshop counts for the navbar badges
      dispatch('fetchWorkshopTotalCounts')
    }
  },
  resetPasswordEmail: ({ dispatch }, email) => {
    return workshopApi.post(`/user/forgot-password?email=${email}`)
  },
  resetPassword: ({ commit }, { resetToken, password }) => {
    return workshopApi.patch(`/user/reset-password?token=${resetToken}`, { password })
  },
  clearWorkshopState: ({ commit, dispatch }) => {
    commit('CLEAR_AUTH_STATE')
    dispatch('clearToastNotificationStore')
    commit('RESET_PROFILE_PHOTO_STATE')
    commit('CLEAR_WORKSHOP_PROCEDURES')
    commit('CLEAR_WORKSHOP_PROFILE_STATE')
    commit('CLEAR_WORKSHOP_ADMIN_USER_STATE')
    commit('CLEAR_PARTICIPANT_USER_DETAILS')
    commit('CLEAR_ATTENDEE_MGMT_STATE')
    commit('CLEAR_WORKSHOP_COURSE_MGMT')
    commit('CLEAR_WORKSHOP_FORM')
    commit('CLEAR_WORKSHOP_HISTORY')
    commit('RESET_USER_MGMT_FORM')
  }
}

const mutations = {
  SAVE_NEW_AUTH_TOKEN: (state, authToken) => {
    /* eslint-disable camelcase */
    const { access_token, refresh_token, expires_in } = authToken

    const newTokenInfo = {
      accessToken: access_token,
      refreshToken: refresh_token,
      accessTokenDuration: expires_in,
      accessTokenRetrievedDate: new Date()
    }
    /* eslint-enable camelcase */

    state.tokenInfo = newTokenInfo
  },
  SET_ME: (state, meDto) => {
    state.me.id = meDto.id
    state.me.email = meDto.email
    state.me.role = meDto.role
    state.me.firstName = meDto.firstName
    state.me.lastName = meDto.lastName
    state.me.fullName = meDto.firstName + ' ' + meDto.lastName
    state.me.isFaculty = meDto.isFaculty
    state.me.isAttendee = meDto.isAttendee
  },
  CLEAR_AUTH_STATE: (state) => {
    Object.assign(state, defaultState())
  }
}

export default {
  state,
  actions,
  getters,
  mutations
}
