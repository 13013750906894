import { workshopApi } from '@/workshop/workshop-api'

const getDefaultState = () => {
  return {
    administrativeUsers: [],
    roles: []
  }
}

const state = getDefaultState()

const getters = {
  administrativeUsers (state) {
    return state.administrativeUsers
  },
  roles (state) {
    return state.roles
  }
}

const actions = {
  fetchAdministrativeUsers: async ({ commit }) => {
    const users = await workshopApi.get('/admin-user/administrative')
    commit('setAdministrativeUsers', users.data)
  },
  fetchRoles: async ({ commit, rootGetters }) => {
    if (!rootGetters.roles.length) {
      const roles = await workshopApi.get('/workshop-role')
      commit('setRoles', roles.data._embedded.workshopRole)
    }
  },
  submitAdministrativeUser: ({ commit }, user) => {
    const userData = {
      firstName: user.firstName,
      lastName: user.lastName,
      role: user.role,
      email: user.email,
      isInternal: user.isInternal
    }
    if (user.phoneNumber) {
      userData.userDetails = { phone: { number: user.phoneNumber } }
    }
    return workshopApi.post(`/admin-user/administrative?send-email=${user.sendRegistrationLink}`, userData)
  },
  updateAdministrativeUser: async ({ dispatch }, user) => {
    const userData = {
      id: user.id,
      firstName: user.firstName,
      lastName: user.lastName,
      role: user.role,
      email: user.email,
      isInternal: user.isInternal,
      userDetails: {
        phone: user.phoneNumber ? { number: user.phoneNumber } : null
      }
    }

    await workshopApi.put('/admin-user/administrative', userData)
    dispatch('fetchAdministrativeUsers')
  },
  deleteAdministrativeUser: async ({ dispatch }, user) => {
    await workshopApi.delete(`/admin-user/administrative/${user.id}`)
    dispatch('fetchAdministrativeUsers')
  },
  findAdministrativeUserByRegistrationToken: ({ commit }, token) => {
    return workshopApi.get(`/admin-user/administrative-unregistered-user?token=${token}`)
  },
  registerAdministrativeUser: ({ commit }, { registrationToken, user }) => {
    return workshopApi.patch(`/admin-user/register-administrative-user?token=${registrationToken}`, user)
  },
  sendRegistrationEmail: ({ commit }, user) => {
    return workshopApi.post('/admin-user/send-registration-email', user)
  }
}

const mutations = {
  setAdministrativeUsers (state, administrativeUsers) {
    state.administrativeUsers = administrativeUsers
  },
  setRoles (state, roles) {
    state.roles = roles
  },
  CLEAR_WORKSHOP_ADMIN_USER_STATE (state) {
    Object.assign(state, getDefaultState())
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
