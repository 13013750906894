<template>
  <div class="base-list">
    <slot></slot>
  </div>
</template>

<style lang="scss" scoped>
.base-list {
  display: flex;
  flex-direction: column;
  background: $surface-color;
  overflow: auto;
}
</style>
