import { workshopApi } from '@/workshop/workshop-api'

const getDefaultState = () => {
  return {
    profilePhoto: {
      fileName: '',
      filePath: ''
    },
    croppedBase64Image: '',
    baseFileName: ''
  }
}

const state = getDefaultState()

const getters = {
  profilePhoto (state) {
    return state.profilePhoto
  },
  profilePhotoPath (state) {
    return state.profilePhoto.filePath || ''
  },
  croppedBase64Image (state) {
    return state.croppedBase64Image
  },
  baseFileName (state) {
    return state.baseFileName
  }
}

const actions = {
  getProfilePhoto: async ({ commit }, userId) => {
    const res = await workshopApi.get('/workshop-participant-profile/profile-picture')
    commit('SET_PROFILE_PHOTO', res.data)
  }
}

const mutations = {
  SET_PROFILE_PHOTO (state, photo) {
    state.profilePhoto = photo
  },
  RESET_PROFILE_PHOTO (state) {
    state.profilePhoto = getDefaultState().profilePhoto
  },
  SET_CROPPED_IMAGE (state, { base64Image, fileName }) {
    state.croppedBase64Image = base64Image
    state.baseFileName = fileName
  },
  RESET_CROPPED_IMAGE (state) {
    state.croppedBase64Image = ''
    state.baseFileName = ''
  },
  RESET_PROFILE_PHOTO_STATE (state) {
    Object.assign(state, getDefaultState())
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
