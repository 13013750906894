<template>
  <router-link v-if="to" :to="to" class="list-item-link">
    <div v-if="$slots.prepend">
      <slot name="prepend"></slot>
    </div>
    <div class="list-item-content">
      <slot>{{ title }}</slot>
    </div>
    <div v-if="$slots.append">
      <slot name="append"></slot>
    </div>
  </router-link>
  <div
    v-else
    :class="['list-item', { 'list-item-active': active }]"
  >
    <div v-if="$slots.prepend">
      <slot name="prepend"></slot>
    </div>
    <div class="list-item-content">
      <slot>{{ title }}</slot>
    </div>
    <div v-if="$slots.append">
      <slot name="append"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    to: {
      type: String,
      required: false
    },
    /**
     * The title can be the content of the list item.
     * It acts as the fallback content for the defualt slot.
     * If we only want to show the name of the item, we can pass it as a "title" prop without creating a slot.
     */
    title: {
      type: String,
      required: false
    },
    /**
     * The active prop is used to determine if the item is active or selected from the list.
     * It is used for highlighting the active item in a list.
     */
    active: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_colors.scss";

.list-item {
  display: flex;
  align-items: center;
  padding: 4px 16px;
  min-height: 48px;
  cursor: pointer;
  color: $neutral-typography-dark;
}

.list-item:hover {
  background-color: $hover-bg-color;
}

.list-item-link {
  color: $neutral-typography-dark;
}

.router-link-exact-active, .list-item-active {
  color: $neutral-typography-dark;
  background-color: #E7E7E7;
}
</style>
