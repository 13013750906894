import { workshopApi } from '@/workshop/workshop-api'
import { randomAlphanumericString } from '@/utils/string-util'

const getDefaultState = () => {
  return {
    registrationFormSteps: [],
    workshopDetails: {
      id: '',
      title: '',
      location: '',
      description: '',
      facilitators: [],
      price: 0,
      isHoldFee: false
    },
    registrationDetails: {
      registrationToken: '',
      isRegistered: false,
      status: ''
    },
    registrationForm: {
      accountDetails: {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: '',
        isNewUser: null
      },
      userHospital: {
        hospitalAffiliation: '',
        streetAddress: '',
        secondaryAddress: '',
        city: '',
        state: '',
        country: 'USA',
        zipCode: ''
      },
      userPhone: {
        primaryPhoneNumber: '',
        cellPhoneNumber: ''
      },
      userMedicalCredentials: {
        status: '',
        medicalLicenseNumber: '',
        medicalLicenseState: '',
        nationalProviderId: ''
      },
      userDietaryRestrictions: {
        canAcceptMeals: null,
        restrictions: 'No'
      },
      specialty: [],
      photoVideoConsent: null,
      flightArrangements: {
        isFlightRequired: null,
        isFlightBookedByApplied: null,
        preferredAirport: null,
        departureDate: null,
        departureTime: null,
        fullname: '',
        birthdate: null,
        tsaPrecheckNumber: '',
        frequentFlyerNumber: ''
      },
      hotelArrangements: {
        isHotelRequired: null,
        checkInDate: null,
        checkOutDate: null
      },
      cardDetails: {
        paymentId: '',
        customerId: '',
        cardType: '',
        lastDigits: '',
        expirationDate: ''
      },
      policyAgreement: {
        acceptPortalTerms: false,
        acceptCancellationPolicy: false,
        acceptReimbursementGuidelines: false,
        acceptFlightPayment: false,
        acceptHotelReimbursement: false
      }
    },
    attendeeProfilePicturePath: ''
  }
}

const state = getDefaultState()

const getters = {
  registrationToken (state) {
    return state.registrationDetails?.registrationToken
  },
  registrationFormSteps (state) {
    return state.registrationFormSteps
  },
  registrationForm (state) {
    return state.registrationForm
  },
  workshopDetails (state) {
    return state.workshopDetails
  },
  registrationDetails (state) {
    return state.registrationDetails
  },
  attendeeProfilePicturePath (state) {
    return state.attendeeProfilePicturePath
  }
}

const actions = {
  submitAttendeeRegistration: ({ state }, { token, imgFile }) => {
    const formData = new FormData()
    formData.append('registrationDetails', new Blob([JSON.stringify(state.registrationForm)], { type: 'application/json' }))
    if (imgFile) {
      formData.append('attachment', imgFile)
    }

    return workshopApi.post(`/workshop-registration/register/${token}`, formData, {
      headers: { 'Content-Type': `multipart/form-data; boundary=WebKitFormBoundary${randomAlphanumericString()}` }
    })
  },
  fetchAttendeeRegistrationDetails: async ({ commit, state }, token) => {
    const resp = await workshopApi.get(`/workshop-registration/details/${token}`)
    const { workshop, attendee, registration } = resp.data
    commit('SET_WORKSHOP_DETAILS', workshop)
    commit('SET_REGISTRATION_DETAILS', registration)
    commit('SET_REGISTRATION_FORM', attendee)
    commit('SET_ATTENDEE_PROFILE_PICTURE_PATH', attendee.profilePicturePath)
    if (state.registrationForm.userHospital.country === null) {
      // Set to USA by default
      commit('SET_ATTENDEE_HOSPITAL_COUNTRY', 'USA')
    }
  }
}

const mutations = {
  SET_WORKSHOP_DETAILS (state, workshop) {
    state.workshopDetails = workshop
  },
  SET_REGISTRATION_DETAILS (state, registration) {
    state.registrationDetails = registration
  },
  SET_REGISTRATION_FORM (state, attendee) {
    state.registrationForm = { ...state.registrationForm, ...attendee }
  },
  SET_REGISTRATION_FORM_STEPS (state, steps) {
    state.registrationFormSteps = steps
  },
  SET_ATTENDEE_FIRST_NAME (state, firstName) {
    state.registrationForm.accountDetails.firstName = firstName
  },
  SET_ATTENDEE_LAST_NAME (state, lastName) {
    state.registrationForm.accountDetails.lastName = lastName
  },
  SET_ATTENDEE_EMAIL (state, email) {
    state.registrationForm.accountDetails.email = email
  },
  SET_ATTENDEE_PASSWORD (state, password) {
    state.registrationForm.accountDetails.password = password
  },
  SET_ATTENDEE_CONFIRM_PASSWORD (state, confirmPassword) {
    state.registrationForm.accountDetails.confirmPassword = confirmPassword
  },
  SET_ATTENDEE_MEDICAL_CREDENTIALS_STATUS (state, status) {
    state.registrationForm.userMedicalCredentials.status = status
  },
  SET_ATTENDEE_HOSPITAL_AFFILIATION (state, hospitalAffiliation) {
    state.registrationForm.userHospital.hospitalAffiliation = hospitalAffiliation
  },
  SET_ATTENDEE_HOSPITAL_ADDRESS (state, address) {
    state.registrationForm.userHospital.streetAddress = address
  },
  SET_ATTENDEE_HOSPITAL_ADDRESS2 (state, address2) {
    state.registrationForm.userHospital.secondaryAddress = address2
  },
  SET_ATTENDEE_HOSPITAL_CITY (state, city) {
    state.registrationForm.userHospital.city = city
  },
  SET_ATTENDEE_HOSPITAL_STATE (state, hospitalState) {
    state.registrationForm.userHospital.state = hospitalState
  },
  SET_ATTENDEE_HOSPITAL_COUNTRY (state, country) {
    state.registrationForm.userHospital.country = country
  },
  SET_ATTENDEE_HOSPITAL_ZIP_CODE (state, zipCode) {
    state.registrationForm.userHospital.zipCode = zipCode
  },
  SET_ATTENDEE_PRIMARY_PHONE (state, primaryPhone) {
    state.registrationForm.userPhone.primaryPhoneNumber = primaryPhone
  },
  SET_ATTENDEE_CELL_PHONE (state, cellPhone) {
    state.registrationForm.userPhone.cellPhoneNumber = cellPhone
  },
  SET_ATTENDEE_MEDICAL_LICENSE_NUMBER (state, licenseNumber) {
    state.registrationForm.userMedicalCredentials.medicalLicenseNumber = licenseNumber
  },
  SET_ATTENDEE_MEDICAL_LICENSE_STATE (state, licenseState) {
    state.registrationForm.userMedicalCredentials.medicalLicenseState = licenseState
  },
  SET_ATTENDEE_NATIONAL_PROVIDER_ID (state, nationalProviderId) {
    state.registrationForm.userMedicalCredentials.nationalProviderId = nationalProviderId
  },
  SET_ATTENDEE_CAN_ACCEPT_MEALS (state, acceptable) {
    state.registrationForm.userDietaryRestrictions.canAcceptMeals = acceptable
  },
  SET_ATTENDEE_DIETARY_RESTRICTIONS (state, restrictions) {
    state.registrationForm.userDietaryRestrictions.restrictions = restrictions
  },
  SET_ATTENDEE_SPECIALTY (state, specialty) {
    state.registrationForm.specialty = specialty
  },
  SET_ATTENDEE_FLIGHT_REQUIRED (state, isFlightRequired) {
    if (!isFlightRequired) {
      state.registrationForm.flightArrangements = getDefaultState().registrationForm.flightArrangements
    }
    state.registrationForm.flightArrangements.isFlightRequired = isFlightRequired
  },
  SET_ATTENDEE_FLIGHT_BOOKED_BY_APPLIED (state, bookedByApplied) {
    state.registrationForm.flightArrangements.isFlightBookedByApplied = bookedByApplied
  },
  SET_ATTENDEE_FLIGHT_ARRANGEMENTS (state, flightArrangements) {
    state.registrationForm.flightArrangements = flightArrangements
  },
  SET_ATTENDEE_HOTEL_REQUIRED (state, isHotelRequired) {
    if (!isHotelRequired) {
      state.registrationForm.hotelArrangements = getDefaultState().registrationForm.hotelArrangements
    }
    state.registrationForm.hotelArrangements.isHotelRequired = isHotelRequired
  },
  SET_ATTENDEE_HOTEL_ARRANGEMENTS (state, hotelArrangements) {
    state.registrationForm.hotelArrangements = hotelArrangements
  },
  SET_ATTENDEE_PHOTO_VIDEO_CONSENT (state, consent) {
    state.registrationForm.photoVideoConsent = consent
  },
  SET_ATTENDEE_CARD_DETAILS (state, cardDetails) {
    state.registrationForm.cardDetails = cardDetails
  },
  SET_ATTENDEE_ACCEPT_PORTAL_TERMS (state, accept) {
    state.registrationForm.policyAgreement.acceptPortalTerms = accept
  },
  SET_ATTENDEE_ACCEPT_CANCELLATION_POLICY (state, accept) {
    state.registrationForm.policyAgreement.acceptCancellationPolicy = accept
  },
  SET_ATTENDEE_ACCEPT_REIMBURSEMENT_GUIDELINES (state, accept) {
    state.registrationForm.policyAgreement.acceptReimbursementGuidelines = accept
  },
  SET_ATTENDEE_ACCEPT_FLIGHT_PAYMENT (state, accept) {
    state.registrationForm.policyAgreement.acceptFlightPayment = accept
  },
  SET_ATTENDEE_ACCEPT_HOTEL_REIMBURSEMENT (state, accept) {
    state.registrationForm.policyAgreement.acceptHotelReimbursement = accept
  },
  SET_ATTENDEE_PROFILE_PICTURE_PATH (state, path) {
    state.attendeeProfilePicturePath = path
  },
  SET_COMPLETED_STEP (state, stepIndex) {
    const index = state.registrationFormSteps.findIndex(step => step.id === stepIndex)
    if (index !== -1) {
      state.registrationFormSteps[index].isCompleted = true
    }
  },
  RESET_ATTENDEE_PROFILE_PICTURE_PATH (state) {
    state.attendeeProfilePicturePath = ''
  },
  RESET_REGISTRATION_FORM (state) {
    Object.assign(state, getDefaultState())
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
