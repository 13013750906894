import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import tableFilter from '@/store/modules/table-filter'
import auth from './modules/auth'
import layout from './modules/layout'
import workshopForm from './modules/admin/workshop-form'
import attendeeRegistrationForm from './modules/registration/attendee-registration-form'
import workshopTableFilter from '@/workshop/store/modules/filter/workshop-table-filter'
import workshopHistory from '@/workshop/store/modules/admin/workshop-history'
import workshopHistoryCount from '@/workshop/store/modules/admin/workshop-history-count'
import workshopCourseMgmtModule from '@/workshop/store/modules/admin/workshop-course-mgmt'
import workshopAttendeeMgmt from './modules/admin/workshop-attendee-mgmt'
import adminUser from './modules/admin/admin-user'
import procedure from './modules/workshop-procedure'
import interactionAnalytics from './modules/interaction-analytics'
import toastNotification from '@/store/notifications/toastNotification'
import userMgmtForm from '@/workshop/store/modules/admin/workshop-user-mgmt-form'
import participantUserDetails from '@/workshop/store/modules/admin/participant-user-details'
import facultyRegistration from '@/workshop/store/modules/registration/faculty-registration'
import participantProfileDetails from '@/workshop/store/modules/participant/participant-profile-details'
import userProfileForm from '@/workshop/store/modules/dashboard/workshop-user-profile-form'

const persistedStateOptions = {
  reducer: state => ({
    auth: state.auth,
    procedure: state.procedure,
    attendeeRegistrationForm: state.attendeeRegistrationForm,
    workshopHistoryCount: state.workshopHistoryCount,
    participantProfileDetails: state.participantProfileDetails
  }),
  storage: window.sessionStorage
}

export default createStore({
  modules: {
    auth,
    layout,
    tableFilter,
    workshopForm,
    workshopTableFilter,
    workshopHistory,
    workshopHistoryCount,
    workshopCourseMgmtModule,
    attendeeRegistrationForm,
    workshopAttendeeMgmt,
    adminUser,
    procedure,
    interactionAnalytics,
    toastNotification,
    userMgmtForm,
    participantUserDetails,
    facultyRegistration,
    participantProfileDetails,
    userProfileForm
  },
  plugins: [createPersistedState(persistedStateOptions)]
})
