import { workshopApi } from '@/workshop/workshop-api'
import { randomAlphanumericString } from '@/utils/string-util'

const getDefaultState = () => {
  return {
    currentWorkshopCourses: [],
    allWorkshopCourses: [],
    workshopId: ''
  }
}

const state = getDefaultState()

const getters = {
  currentWorkshopCourses (state) {
    return state.currentWorkshopCourses
  },
  allWorkshopCourses (state) {
    return state.allWorkshopCourses
  }
}

const actions = {
  async fetchCurrentWorkshopCourses ({ commit }, workshopId) {
    const res = await workshopApi.get(`/admin-courses/workshop/${workshopId}`)
    const currentWorkshopCourses = res.data.toSorted((a, b) => a.order - b.order)

    commit('SET_CURRENT_WORKSHOP_COURSES', currentWorkshopCourses)
  },
  async fetchAllWorkshopCourses ({ commit }) {
    const res = await workshopApi.get('/admin-courses/all')

    const allWorkshopCourses = res.data.toSorted((a, b) => a.order - b.order)

    commit('SET_ALL_WORKSHOP_COURSES', allWorkshopCourses)
  },
  async saveWorkshopCourses ({ state, dispatch }, courses) {
    const selectedCourses = courses.map(course => {
      return {
        course: { id: course.id },
        workshop: { id: state.workshopId }
      }
    })

    await workshopApi.post('/admin-courses/batch-add', selectedCourses)

    await dispatch('fetchCurrentWorkshopCourses', state.workshopId)
  },
  async deleteWorkshopCourse ({ state, dispatch }, selectedCourseId) {
    const selectedCourse = {
      data: {
        id: selectedCourseId
      }
    }
    await workshopApi.delete('/admin-courses', selectedCourse)

    await dispatch('fetchCurrentWorkshopCourses', state.workshopId)
  },
  async addWorkshopDidactic ({ state, dispatch }, payload) {
    const selectedCourse = {
      id: payload.selectedCourseId
    }
    await workshopApi.post(`/admin-courses/add-didactic/${payload.didacticId}`, selectedCourse)

    await dispatch('fetchCurrentWorkshopCourses', state.workshopId)
  },
  async removeWorkshopDidactic ({ state, dispatch }, payload) {
    const selectedCourse = {
      data: {
        id: payload.selectedCourseId
      }
    }
    await workshopApi.delete(`/admin-courses/remove-didactic/${payload.didacticId}`, selectedCourse)

    await dispatch('fetchCurrentWorkshopCourses', state.workshopId)
  },
  async updateDidacticVersion ({ state, dispatch }, payload) {
    const selectedCourse = {
      id: payload.selectedCourseId
    }
    await workshopApi.put(`/admin-courses/update-didactic-version/${payload.newDidacticVersionId}`, selectedCourse)

    await dispatch('fetchCurrentWorkshopCourses', state.workshopId)
  },
  async uploadWorkshopAttachment ({ state, dispatch }, payload) {
    const form = new FormData()
    form.append('selectedCourse', new Blob([JSON.stringify(payload.selectedCourse)], { type: 'application/json' }))
    form.append('attachment', payload.attachment)

    await workshopApi.post('/admin-courses/add-attachment', form, {
      headers: { 'Content-Type': `multipart/form-data; boundary=WebKitFormBoundary${randomAlphanumericString()}` }
    })

    await dispatch('fetchCurrentWorkshopCourses', state.workshopId)
  },
  async deleteWorkshopAttachment ({ state, dispatch }, payload) {
    const selectedCourse = {
      data: {
        id: payload.selectedCourseId
      }
    }
    await workshopApi.delete(`/admin-courses/remove-attachment/${payload.attachmentId}`, selectedCourse)

    await dispatch('fetchCurrentWorkshopCourses', state.workshopId)
  }
}

const mutations = {
  SET_CURRENT_WORKSHOP_COURSES (state, courses) {
    state.currentWorkshopCourses = courses
  },
  ADD_CURRENT_WORKSHOP_COURSE (state, course) {
    state.currentWorkshopCourses.push(course)
  },
  SET_ALL_WORKSHOP_COURSES (state, courses) {
    Object.assign(state.allWorkshopCourses, courses)
  },
  SET_COURSE_MGMT_WORKSHOP_ID (state, workshopId) {
    state.workshopId = workshopId
  },
  CLEAR_WORKSHOP_COURSE_MGMT (state) {
    Object.assign(state, getDefaultState())
  }
}

export default {
  state,
  actions,
  getters,
  mutations
}
