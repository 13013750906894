import { workshopApi } from '@/workshop/workshop-api'

const getDefaultState = () => {
  return {
    pastWorkshopsTotalCount: 0,
    upcomingWorkshopsTotalCount: 0
  }
}

const state = getDefaultState()

const getters = {
  pastWorkshopTotalCount: state => state.pastWorkshopsTotalCount,
  upcomingWorkshopTotalCount: state => state.upcomingWorkshopsTotalCount
}

const actions = {
  async fetchWorkshopTotalCounts ({ dispatch }) {
    await dispatch('fetchPastWorkshopsTotalCount')
    await dispatch('fetchUpcomingWorkshopsTotalCount')
  },
  async fetchPastWorkshopsTotalCount ({ commit }) {
    const res = await workshopApi.get('/workshop/search/totalCountPastWorkshops')
    commit('SET_PAST_WORKSHOPS_TOTAL_COUNT', res.data)
  },
  async fetchUpcomingWorkshopsTotalCount ({ commit }) {
    const res = await workshopApi.get('/workshop/search/totalCountUpcomingWorkshops')
    commit('SET_UPCOMING_WORKSHOPS_TOTAL_COUNT', res.data)
  }
}

const mutations = {
  SET_PAST_WORKSHOPS_TOTAL_COUNT (state, count) {
    state.pastWorkshopsTotalCount = count
  },
  SET_UPCOMING_WORKSHOPS_TOTAL_COUNT (state, count) {
    state.upcomingWorkshopsTotalCount = count
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
