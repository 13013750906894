<template>
  <tbody>
    <div
      v-for="item in processedItemsWithExpand"
      :key="item.key"
      class="row-contents"
    >
      <tr
        :class="[tbodyTrClass(item), { 'hover-highlight': highlightOnHover }]"
        :data-cy="rowDataCy"
        @click="$emit('click:row', item)"
      >
        <template v-if="!item.expandSlot">
          <td
            v-for="field in fields"
            :key="field.value"
            class="default-td"
            :class="[field['tdClass'] ? field['tdClass'].split(' ') : '', { 'expanded-row': isRowExpanded(item) }]"
            :style="field.tdStyle"
          >
            <slot :name="field.value" :item="item" :toggleDetails="toggleDetails(item)">
              <div>
                {{ field.formatter ? field.formatter(item[field.value]) : item[field.value] }}
              </div>
            </slot>
          </td>
        </template>
        <td v-else class="expand-td" colspan="100%">
          <slot name="details" :item="item"></slot>
        </td>
      </tr>
      <div
        v-if="isRowExpanded(item)"
        class="row-contents"
        :class="[tbodyTrClass(item)]"
      >
        <slot name="expanded-slot" :item="item"></slot>
      </div>
    </div>
    <tr v-if="!processedItemsWithExpand.length">
      <td :colspan="fields.length">
        <slot name="no-data-placeholder"></slot>
      </td>
    </tr>
  </tbody>
</template>

<script>
export default {
  props: {
    fields: {
      type: Array,
      required: true
    },
    processedItems: {
      type: Array,
      required: true
    },
    tbodyTrClass: {
      type: Function,
      required: false,
      default: (item) => ''
    },
    highlightOnHover: {
      type: Boolean,
      required: false,
      default: false
    },
    showExpand: {
      type: Boolean,
      required: false,
      default: false
    },
    expandedRows: {
      type: Array,
      required: false
    },
    rowDataCy: {
      type: String,
      required: false
    }
  },
  emits: ['click:row'],
  data () {
    return {
      processedItemsWithExpand: []
    }
  },
  watch: {
    processedItems (to, from) {
      this.updateItems()
    }
  },
  methods: {
    updateItems () {
      const items = []
      this.processedItems.forEach(item => {
        items.push(item)
        if (item.showDetails) {
          items.push({ ...item, expandSlot: true })
        }
      })
      this.processedItemsWithExpand = items
    },
    isRowExpanded (item) {
      if (this.showExpand) {
        const rowIndex = item.rowIndex
        return this.expandedRows.includes(rowIndex)
      }
    },
    toggleDetails (item) {
      return () => {
        item.showDetails = !item.showDetails
        this.updateItems()
      }
    }
  },
  mounted () {
    this.updateItems()
  }
}
</script>

<style lang="scss" scoped>
@import "@/components/table/table-mixin.scss";

.default-td {
  @include basetable-td;

  border-bottom: 1px solid $neutral-lighter-grey;
  border-top: 1px solid $neutral-lighter-grey;
}

:slotted(.expanded-td) {
  @include basetable-td;

  a {
    text-decoration: none;
    color: $primary-digital-teal-default;
  }

  a:hover {
    text-decoration: underline;
  }
}

.expanded-row-title {
  padding-left: 25px;
}

.light-text {
  color: $neutral-typography-medium;
}

.default-td.td {
  height: 10px;
}

.expand-td {
  border-top: 0px;
  padding: 0px 0.75rem;
}

.hover-highlight:hover, .hover-highlight:active {
  td {
    background-color: $neutral-background-color;
    cursor: pointer;
  }
}

.expanded-row {
  font-family: "Open Sans Semi-Bold";
  border-bottom: none;
}

.row-contents {
  width: 100%;
  display: contents;
}
</style>
