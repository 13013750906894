import { workshopApi } from '@/workshop/workshop-api'

const actions = {
  findFacultyUserByRegistrationToken: ({ commit }, token) => {
    return workshopApi.get(`/faculty/unregistered-user?token=${token}`)
  },
  registerFacultyUser: ({ commit }, { registrationToken, user }) => {
    return workshopApi.patch(`/faculty/register-user?token=${registrationToken}`, user)
  }
}

export default {
  actions
}
