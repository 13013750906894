<template>
  <thead>
    <tr>
      <th
        v-for="field in fields"
        :key="field.title"
        @click="$emit('toggleSort', field)"
        class="default-th"
        :class="[field['thClass'] ? field['thClass'].split(' ') : '']"
        :style="field.thStyle"
      >
        <div class="flex-space">
          <div class="field-text">
            <span class="text">{{ field.text }}</span>
            <span v-if="field.subtext" class="subtext">{{ field.subtext }}</span>
          </div>
          <div
            v-if="field.sortable"
            class="arrows"
          >
            <img
              v-if="sortDesc && sortedField === field.value"
              class="up-arrow"
              src="@/assets/icons/dropdown-thin.svg"
            />
            <img
              v-else
              class="down-arrow"
              src="@/assets/icons/dropdown-thin.svg"
            />
          </div>
        </div>
      </th>
    </tr>
  </thead>
</template>

<script>
export default {
  props: {
    fields: {
      type: Array,
      required: true
    },
    sortDesc: {
      type: Boolean,
      required: true
    },
    sortedField: {
      type: String,
      required: true
    }
  },
  emits: ['toggleSort']
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_mixins.scss";
@import "@/components/table/table-mixin.scss";

.default-th {
  @include table-default-th;
}

.flex-space {
  display: flex;
  gap: 4px;
  flex-flow: row;
  align-items: center;
}

.arrows {
  display: flex;
  flex-direction: column;
  padding-left: 8px;
}

.down-arrow {
  @include disabled-svg;

  width: 10px;
}

.up-arrow {
  @include disabled-svg;

  width: 10px;
  transform: rotate(-180deg);
  padding-top: 2px; // because its rotated top padding is bottom
}

.field-text {
  display: flex;
  flex-direction: column;
}

.subtext {
  font-weight: 400;
  font-family: "Open Sans";
}
</style>
