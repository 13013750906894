import { workshopApi } from '@/workshop/workshop-api.js'
import { paymentStatusEnums } from '@/workshop/consts/workshop-attendee-enum.js'

const selectionModeEnum = {
  emailSelection: 'EMAIL_SELECTION',
  registrationSelection: 'REGISTRATION_SELECTION'
}

function sortAttendee (attendeeA, attendeeB) {
  const aName = `${attendeeA.firstName} ${attendeeA.lastName}`
  const bName = `${attendeeB.firstName} ${attendeeB.lastName}`
  return aName.localeCompare(bName)
}

const getDefaultState = () => {
  return {
    workshopId: null,
    selectionMode: null,
    selectedUsers: [],
    activeAttendees: [],
    inactiveAttendees: [],
    selectedAttendeeDetails: {}
  }
}

const state = getDefaultState()

const getters = {
  manageAttendeesWorkshopId (state) {
    return state.workshopId
  },
  isEmailSelectionMode (state) {
    return state.selectionMode === selectionModeEnum.emailSelection
  },
  isRegistrationSelectionMode (state) {
    return state.selectionMode === selectionModeEnum.registrationSelection
  },
  selectedUsers (state) {
    return state.selectedUsers
  },
  isSelectionModeActive (state) {
    return state.selectionMode !== null
  },
  activeAttendees (state) {
    return state.activeAttendees
  },
  inactiveAttendees (state) {
    return state.inactiveAttendees
  },
  selectedAttendeeDetails (state) {
    return state.selectedAttendeeDetails
  }
}

const actions = {
  setEmailSelectionMode ({ commit }) {
    commit('RESET_USERS_SELECTED')
    commit('SET_SELECTION_MODE', selectionModeEnum.emailSelection)
  },
  setRegistrationSelectionMode ({ commit }) {
    commit('RESET_USERS_SELECTED')
    commit('SET_SELECTION_MODE', selectionModeEnum.registrationSelection)
  },
  exitSelectionMode ({ commit }) {
    commit('RESET_USERS_SELECTED')
    commit('SET_SELECTION_MODE', null)
  },
  addUser ({ commit }, user) {
    commit('ADD_SELECTED_USER', user)
  },
  removeUser ({ state, commit }, user) {
    commit('SET_USERS_SELECTED', state.selectedUsers.filter(selectedUser => selectedUser.email !== user.email))
  },
  clearSelectedUsers ({ commit }) {
    commit('RESET_USERS_SELECTED')
  },
  selectAllUsers ({ commit }, users) {
    commit('SET_USERS_SELECTED', users)
  },
  async fetchActiveWorkshopAttendees ({ getters, commit }) {
    const res = await workshopApi.get(`/admin-attendee/active/${getters.manageAttendeesWorkshopId}`)
    res.data.sort(sortAttendee)
    commit('SET_ACTIVE_ATTENDEES', res.data)
  },
  async fetchInactiveWorkshopAttendees ({ getters, commit }) {
    const res = await workshopApi.get(`/admin-attendee/inactive/${getters.manageAttendeesWorkshopId}`)
    res.data.sort(sortAttendee)
    commit('SET_INACTIVE_ATTENDEES', res.data)
  },
  setAttendeeIsActive ({ commit }, payload) {
    return workshopApi.patch(`/admin-attendee/set-is-active/${payload.attendee.id}?isActive=${payload.isActive}`)
  },
  submitNewWorkshopAttendee ({ commit }, payload) {
    return workshopApi.post(`/admin-attendee/add-new-attendee/${payload.workshopId}`, payload.attendee)
  },
  submitExistingWorkshopAttendee ({ commit }, payload) {
    return workshopApi.post(`/admin-attendee/add-existing-attendee/${payload.workshopId}`, payload.attendee)
  },
  processPayment ({ commit }, attendeeId) {
    return workshopApi.post(`/admin-attendee/process-payment/${attendeeId}`)
  },
  sendAttendeeRegistrationEmail ({ commit }, attendeeId) {
    return workshopApi.patch(`/admin-attendee/send-registration-email/${attendeeId}`)
  },
  sendAttendeeRegistrationEmails ({ getters }) {
    const attendeeIds = getters.selectedUsers.map(attendee => attendee.id)
    return workshopApi.patch('/admin-attendee/send-registration-emails', attendeeIds)
  }
}

const mutations = {
  SET_MANAGE_ATTENDEE_WORKSHOP_ID (state, workshopId) {
    state.workshopId = workshopId
  },
  SET_SELECTION_MODE (state, selectionMode) {
    state.selectionMode = selectionMode
  },
  RESET_USERS_SELECTED (state) {
    state.selectedUsers = []
  },
  SET_USERS_SELECTED (state, users) {
    state.selectedUsers = users
  },
  SET_ACTIVE_ATTENDEES (state, activeAttendees) {
    state.activeAttendees = activeAttendees
  },
  SET_INACTIVE_ATTENDEES (state, inactiveAttendees) {
    state.inactiveAttendees = inactiveAttendees
  },
  ADD_SELECTED_USER (state, user) {
    state.selectedUsers.push(user)
  },
  UPDATE_PAYMENT_STATUS_TO_PAID (state, attendeeId) {
    const attendee = state.activeAttendees.find(attendee => attendee.id === attendeeId)
    if (attendee) {
      attendee.paymentStatus = paymentStatusEnums.PAID
    }
  },
  SET_SELECTED_ATTENDEE_DETAILS (state, attendee) {
    state.selectedAttendeeDetails = attendee
  },
  UPDATE_SELECTED_ACTIVE_ATTENDEE_STATUS (state, isActive) {
    state.selectedAttendeeDetails.attendee.isActive = isActive
  },
  UPDATE_SELECTED_ATTENDEE_PAYMENT_STATUS (state, paymentStatus) {
    state.selectedAttendeeDetails.attendee.paymentStatus = paymentStatus
  },
  UPDATE_SELECTED_ATTENDEE_REGISTRATION_EMAIL_SENT_DATE (state, date) {
    state.selectedAttendeeDetails.attendee.registrationEmailSentDate = date
  },
  CLEAR_ATTENDEE_MGMT_STATE (state) {
    Object.assign(state, getDefaultState())
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
