/**
 * This mixin uses a store value for the window width, which is set by an event listener
 * on the StandardLayout component. Use the isMobile computed property to check for mobile breakpoints.
 * If you want to have multiple break points in a component, create a computed property
 * that checks the store value.
 *
 * It can be used with the default value for mobile:
 *   mixins: [mobileThresholdMixin()]
 *
 * Or it can be used with a pixel value:
 *   mixins: [mobileThresholdMixin(1024)]
 *
 * There are also constants defined in consts/mobile-thresholds.js that can be used to avoid magic numbers:
 *   import { mobileThreshold } from '@/consts/mobile-thresholds.js'
 *   mixins: [mobileThresholdMixin(mobileThreshold.TABLET)]
 *
 * Constants are available that use the values defined in assets/scss/_variables.scss such as
 * SMALL, MEDIUM, LARGE, EXTRA_LARGE, and MOBILE
 */
import { mobileThreshold } from '@/consts/mobile-thresholds.js'

export const mobileThresholdMixin = (threshold = mobileThreshold.MOBILE) => ({
  computed: {
    isMobile () {
      return this.$store.getters.windowWidth <= threshold
    }
  }
})
