import axios from 'axios'
import NProgress from 'nprogress'
import store from '@/workshop/store/store'
import errorMessages from '@/consts/error-messages'
import workshopEndpoints from '@/workshop/workshop-endpoints'

const simseiWorkshopApi = axios.create({
  baseURL: process.env.VUE_APP_WORKSHOP_V1_API
})

simseiWorkshopApi.interceptors.request.use(
  request => {
    NProgress.start()
    // Check the state of the access and refresh token and request a new one if needed
    return checkAccessToken().then(accessToken => {
      if (accessToken) {
        request.headers.Authorization = `Bearer ${accessToken}`
      }
      return request
    }).catch(async err => {
      if (err.message === errorMessages.AUTH_TOKEN_EXPIRED) {
        await logout()
        return Promise.reject(err)
      } else {
        throw err
      }
    })
  },
  error => {
    NProgress.done()
    throw error
  }
)

simseiWorkshopApi.interceptors.response.use(
  response => {
    NProgress.done()
    return response
  },
  async error => {
    NProgress.done()
    if (error.config && error.response?.status === 401) { // Check Token in case accessToken has expired over network latency
      try {
        const accessToken = await checkAccessToken()
        error.config.headers.Authorization = `Bearer ${accessToken}`
        return axios.request(error.config)
      } catch (err) {
        await logout()
        return Promise.reject(err)
      }
    }
    return Promise.reject(error)
  }
)

/**
 * Check the state of the access and refresh token and request a new one if needed. The validity window
 * is used to determine if the access token WILL expire within the next validityWindowMs milliseconds. This
 * can be used to preemptively request a new access token before the current one expires.
 *
 * @param validityWindowMs optional validity window in milliseconds
 * @returns Auth token Promise. If no token exists, an empty Promise is returned. If token is expired, a
 * rejected Promise with the error message `AUTH_TOKEN_EXPIRED` is returned.
 */
function checkAccessToken (validityWindowMs = 0) {
  if (validityWindowMs < 0) {
    throw new Error('validityWindowMs must be greater than 0')
  }

  const { refreshToken, accessTokenDuration } = store.getters.getTokenInfo
  if (!refreshToken || !accessTokenDuration) {
    return Promise.resolve()
  }

  if (!accessTokenIsExpired(validityWindowMs, accessTokenDuration)) {
    return Promise.resolve(store.getters.getTokenInfo.accessToken)
  } else {
    // attempt to get new access token using refresh token
    const params = {
      'grant_type': 'refresh_token',
      'refresh_token': refreshToken
    }
    return axios({
      method: 'POST',
      url: process.env.VUE_APP_BASE_WORKSHOP_API + '/oauth/token',
      params: params,
      headers: { 'Content-type': 'application/x-www-form-urlencoded' },
      auth: {
        username: process.env.VUE_APP_CLIENT_ID,
        password: process.env.VUE_APP_CLIENT_SECRET
      }
    }).then(res => {
      store.commit('SAVE_NEW_AUTH_TOKEN', res.data)
      return store.getters.getTokenInfo.accessToken
    }).catch(err => {
      if (err.response?.status === 401) {
        return Promise.reject(new Error(errorMessages.AUTH_TOKEN_EXPIRED))
      } else {
        throw err
      }
    })
  }
}

function accessTokenIsExpired (validityWindowMs, accessTokenDuration) {
  let { accessTokenRetrievedDate } = store.getters.getTokenInfo

  if (typeof accessTokenRetrievedDate === 'string') {
    accessTokenRetrievedDate = new Date(accessTokenRetrievedDate)
  }

  const currentTime = new Date()
  if (currentTime.getTime() + validityWindowMs > (accessTokenRetrievedDate.getTime() + accessTokenDuration * 1000)) {
    return true
  } else {
    return false
  }
}

async function logout () {
  await store.dispatch('clearWorkshopState')
  window.location = `/${workshopEndpoints.LOGIN}`
}

export const workshopApi = simseiWorkshopApi
