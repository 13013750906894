import { workshopApi } from '@/workshop/workshop-api.js'
import { parseDateAndTime } from '@/utils/date-util.js'

const defaultWorkshopFormState = () => {
  return {
    editWorkshopId: null,
    form: {
      title: '',
      procedure: '',
      type: '',
      date: '',
      startTime: '',
      endTime: '',
      isInternal: false,
      faculty: [],
      facultyAttendees: [],
      description: '',
      facilitators: [],
      price: null,
      isHoldFee: false,
      address: {
        location: '',
        streetAddress: '',
        city: '',
        state: '',
        zipCode: ''
      },
      hasAttendees: false
    }
  }
}

const state = defaultWorkshopFormState()

const getters = {
  workshopForm (state) {
    return state.form
  },
  workshopFormAddress (state) {
    return state.form.address
  },
  workshopIdToEdit (state) {
    return state.editWorkshopId
  }
}

const actions = {
  submitWorkshopForm: async ({ getters }) => {
    const workshopForm = getters.workshopForm
    const workshop = parseWorkshopForm(workshopForm)

    await workshopApi.post('/admin-workshop/create-workshop', workshop)
  },
  prepareEditWorkshop: ({ commit }, workshop) => {
    commit('SET_EDIT_WORKSHOP_ID', workshop.id)
    commit('SET_WORKSHOP_DESCRIPTION', workshop.description)
    commit('SET_WORKSHOP_FULL_ADDRESS', workshop.workshopAddress)
    commit('SET_WORKSHOP_PRICE', workshop.price.toString())
    commit('SET_WORKSHOP_IS_HOLD_FEE', workshop.isHoldFee)
    commit('SET_WORKSHOP_TYPE', workshop.workshopType)
    commit('SET_WORKSHOP_TITLE', workshop.title)
    commit('SET_WORKSHOP_PROCEDURE', workshop.workshopProcedure.id.toString())
    commit('SET_WORKSHOP_IS_INTERNAL', workshop.isInternal)
    commit('SET_WORKSHOP_FACILITATORS', workshop.facilitators)
    commit('SET_WORKSHOP_HAS_ATTENDEES', workshop.hasAttendees)

    const faculty = workshop.faculty.filter(user => user.isFaculty)
    commit('SET_WORKSHOP_FACULTY', faculty)

    const workshopStartDateTime = parseDateAndTime(new Date(workshop.startDate))
    commit('SET_WORKSHOP_DATE', workshopStartDateTime.date)
    commit('SET_WORKSHOP_START_TIME', workshopStartDateTime.time)

    const workshopEndTime = parseDateAndTime(new Date(workshop.endDate)).time
    commit('SET_WORKSHOP_END_TIME', workshopEndTime)
  },
  updateWorkshop: async ({ getters }) => {
    const workshopForm = getters.workshopForm
    const editedWorkshop = parseWorkshopForm(workshopForm)

    await workshopApi.patch(`/admin-workshop/update-workshop/${getters.workshopIdToEdit}`, editedWorkshop)
  }
}

const mutations = {
  SET_EDIT_WORKSHOP_ID (state, id) {
    state.editWorkshopId = id
  },
  SET_WORKSHOP_PROCEDURE (state, procedure) {
    state.form.procedure = procedure
  },
  SET_WORKSHOP_TYPE (state, type) {
    state.form.type = type
  },
  SET_WORKSHOP_DATE (state, date) {
    state.form.date = date
  },
  SET_WORKSHOP_START_TIME (state, startTime) {
    state.form.startTime = startTime
  },
  SET_WORKSHOP_END_TIME (state, endTime) {
    state.form.endTime = endTime
  },
  SET_WORKSHOP_LOCATION (state, location) {
    state.form.address.location = location
  },
  SET_WORKSHOP_FULL_ADDRESS (state, address) {
    state.form.address = address
  },
  SET_WORKSHOP_ADDRESS (state, address) {
    state.form.address.streetAddress = address
  },
  SET_WORKSHOP_CITY (state, city) {
    state.form.address.city = city
  },
  SET_WORKSHOP_STATE (state, selectedState) {
    state.form.address.state = selectedState
  },
  SET_WORKSHOP_ZIP_CODE (state, zip) {
    state.form.address.zipCode = zip
  },
  SET_WORKSHOP_TITLE (state, title) {
    state.form.title = title
  },
  SET_WORKSHOP_IS_INTERNAL (state, isInternal) {
    state.form.isInternal = isInternal
  },
  SET_WORKSHOP_DESCRIPTION (state, description) {
    state.form.description = description
  },
  SET_WORKSHOP_PRICE (state, price) {
    state.form.price = price
  },
  SET_WORKSHOP_IS_HOLD_FEE (state, isHoldFee) {
    state.form.isHoldFee = isHoldFee
  },
  SET_WORKSHOP_FACULTY_ATTENDEES (state, facultyAttendees) {
    state.form.facultyAttendees = facultyAttendees
  },
  SET_WORKSHOP_FACULTY (state, faculty) {
    state.form.faculty = faculty
  },
  SET_WORKSHOP_FACILITATORS (state, facilitators) {
    state.form.facilitators = facilitators
  },
  SET_WORKSHOP_HAS_ATTENDEES (state, hasAttendees) {
    state.form.hasAttendees = hasAttendees
  },
  CLEAR_WORKSHOP_FORM (state) {
    Object.assign(state, defaultWorkshopFormState())
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}

function parseDateTime (date, time) {
  // convert the time to 24hr format manually, Safari does
  // not like the use of 'AM' or 'PM' in the time string
  const timeSplit = time.split(' ')
  let hour = timeSplit[0].split(':')[0]
  const min = timeSplit[0].split(':')[1]
  if (timeSplit[1] === 'AM') {
    hour = hour === '12' ? '00' : hour
  } else {
    hour = hour === '12' ? hour : parseInt(hour) + 12
  }
  const timeIn24HourFormat = `${hour}:${min}:00`
  return `${date}T${timeIn24HourFormat}`
}

function parseWorkshopForm (workshop) {
  return {
    title: workshop.title,
    description: workshop.description,
    startDate: parseDateTime(workshop.date, workshop.startTime),
    endDate: parseDateTime(workshop.date, workshop.endTime),
    type: workshop.type,
    price: workshop.price,
    isHoldFee: workshop.isHoldFee,
    procedureId: workshop.procedure,
    address: workshop.address,
    facilitatorsIds: workshop.facilitators.map(facilitator => facilitator.id),
    facultyIds: parseFacultyUsers([...workshop.faculty, ...workshop.facultyAttendees]),
    isInternal: workshop.isInternal
  }
}

function parseFacultyUsers (allFacultyUsersArray) {
  const facultyIds = new Set()
  allFacultyUsersArray.forEach(facultyUser => facultyIds.add(facultyUser.id))
  return Array.from(facultyIds)
}
