<template>
  <BaseModal
    v-model="show"
    v-bind="props"
    content-class="msg-box-confirm"
    data-testid="msgbox-confirm"
  >
    <BaseCard>
      <BaseCardTitle v-if="title" class="msg-box-confirm-title" data-testid="base-alert-title">
        <h4 data-cy="msg-box-confirm-title-text">{{ title }}</h4>
      </BaseCardTitle>
      <BaseCardText v-if="text" class="msg-box-confirm-text" data-testid="base-alert-text">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <span v-html="text" :style="textStyle"></span>
      </BaseCardText>
      <div class="msg-box-confirm-spacer"></div>
      <div class="msg-box-confirm-buttons" data-testid="base-alert-buttons">
        <BaseButton
          transparent
          :style="cancelStyle"
          colorScheme="gray"
          data-cy="alert-cancel"
          data-testid="alert-cancel"
          @click="cancel"
        >
          {{ cancelText }}
        </BaseButton>
        <BaseButton
          ref="confirmBtn"
          :style="confirmStyle"
          colorScheme="light-teal"
          data-cy="alert-confirm"
          data-testid="alert-confirm"
          @click="confirm"
        >
          {{ computedConfirmText }}
        </BaseButton>
      </div>
    </BaseCard>
  </BaseModal>
</template>

<script>
import BaseButton from '@/components/buttons/BaseButton'
import BaseModal from '@/components/BaseModal'
import BaseCard from '@/components/BaseCard'
import BaseCardTitle from '@/components/BaseCardTitle'
import BaseCardText from '@/components/BaseCardText'

export default {
  name: 'MsgBoxConfirm',
  components: { BaseButton, BaseModal, BaseCard, BaseCardTitle, BaseCardText },
  props: {
    modelValue: {
      type: Boolean,
      required: false,
      default: true
    },
    title: {
      type: String,
      required: false
    },
    text: {
      type: String,
      required: false
    },
    textStyle: {
      type: Object,
      required: false
    },
    confirmText: {
      type: String,
      required: false
    },
    cancelText: {
      type: String,
      required: false,
      default: 'CANCEL'
    },
    vDialogProps: {
      type: Object,
      required: false
    },
    width: {
      type: Number,
      required: false,
      default: 510
    },
    cancelStyle: {
      type: Object,
      required: false,
      default: () => {
        return { }
      }
    },
    confirmStyle: {
      type: Object,
      required: false,
      default: () => {
        return { }
      }
    },
    // Instance of mitt passed into us from base-alert-modal.js
    emitter: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      show: this.modelValue
    }
  },
  computed: {
    // The props which to pass along to <v-dialog>
    props () {
      const defaultProps = {
        'persistent': true,
        'width': this.width,
        'origin': 'top'
      }
      return { ...defaultProps, ...this.vDialogProps }
    },
    computedConfirmText () {
      if (this.confirmText) return this.confirmText
      return 'CONFIRM'
    }
  },
  mounted () {
    setTimeout(() => {
      this.$refs.confirmBtn.$el.focus()
    }, 10)
  },
  watch: {
    $route (to, from) {
      // cancel on forced route change, eg when redirecting to login page after auth token expiration
      this.emitter.emit('cancel')
    }
  },
  methods: {
    cancel () {
      this.show = false
      this.emitter.emit('cancel')
    },
    confirm () {
      this.show = false
      this.emitter.emit('confirm')
    }
  }
}
</script>

<style lang="scss">
// This style is not scoped because this styling is not being applied when scoped.
// Likely due to being instantiated with createApp()
@import "@/components/alert/msg-alert-mixin.scss";

// Change position of the modal from center to top
.msg-box-confirm {
  top: -45%;
}

.msg-box-confirm-title {
  @include alert-msg-title;

  font-size: 18px;
  padding-top: 20px !important;
  padding-left: 24px;
}

.msg-box-confirm-title h4 {
  @include alert-msg-title-h4;
}

.msg-box-confirm-text {
  @include alert-msg-text;

  padding-top: 16px !important;
  padding-bottom: 22px !important;
  font-size: 16px;
}

.msg-box-confirm-spacer {
  width: 100%;
  min-height: 10px;
}

.msg-box-confirm-buttons {
  display: flex;
  justify-content: flex-end;
  margin-right: 8px;
  padding-bottom: 20px !important;
  padding-right: 0.5rem;
}
</style>
