<template>
  <BaseButtonIcon icon="hamburger_menu.svg" @click="show = true"></BaseButtonIcon>
  <BaseModal
    v-model="show"
    fullscreen
  >
    <BaseCard>
      <BaseButtonIcon icon="x.svg" class="float-right close-btn" @click="show = false" />
      <nav class="card-content">
        <div v-for="navItem in navItems" class="container-link" :key="navItem.name" @click="show = false">
          <router-link :class="['route-link', {'active-link': isCurrent(navItem)}]" :to="navItem.link">
            {{ navItem.name }}
            <img v-if="hasNotif(navItem.notif)" class="red-dot-icon" src="@/assets/icons/tiny-red-circle.svg">
          </router-link>
        </div>
      </nav>
    </BaseCard>
  </BaseModal>
</template>

<script>
import { useBeforeRouteChange } from '@/composables/useBeforeRouteChange'

export default {
  props: {
    navItems: {
      type: Array,
      required: true
    }
  },
  setup () {
    const { setBeforeRouteChangeCallback } = useBeforeRouteChange()
    return { setBeforeRouteChangeCallback }
  },
  data () {
    return {
      show: false
    }
  },
  mounted () {
    this.setBeforeRouteChangeCallback(this.onBeforeRouteChange)
  },
  methods: {
    hasNotif (notifName) {
      return this.$store.getters[notifName]
    },
    isCurrent (item) {
      // Checks if the navbar link is equal to the current route path
      // If so, underline the navbar item
      const path = this.$route.path
      if (path.indexOf(item.link) === 0) {
        return true
      }

      // Navbar items also has an 'alias path'
      // Ex. '/courses' has a '/course' alias
      if (!item.alias) {
        return false
      }
      for (const link of item.alias) {
        if (path.indexOf(link) === 0) {
          return true
        }
      }
    },
    onBeforeRouteChange (to, from, next, backButtonUsed) {
      if (backButtonUsed && this.show) {
        this.show = false
        next(false)
        return
      }
      next()
    }
  }
}
</script>

<style scoped lang="scss">
.card-content {
  padding: 24px;
  height: 100%;
}

.container-link {
  display: flex;
  width: 100%;
  border-bottom: 1px solid $bg-light-grey;

  &:last-of-type {
    border-bottom: none;
  }
}

.route-link {
  position: relative;
  font-size: 15px;
  text-decoration: none;
  color: $neutral-typography-dark;
  padding: 24px 0;
  width: 100%;
}

.active-link {
  font-weight: 600;
  color: $primary-digital-teal-default;
}

.red-dot-icon {
  position: absolute;
  height: 0.5rem;
  width: 0.5rem;
}

.close-btn {
  float: right;

  // Overrides the BaseButtonIcon margins
  margin-top: 16px !important;
  margin-right: 16px !important;
  background-color: $bg-light-grey;
}

.float-right {
  align-self: flex-end;
}
</style>
