import residencyLayouts from '@/residency/consts/residency-layouts'

const state = {
  layout: residencyLayouts.SIMPLE_LAYOUT,
  mobileSidebar: {
    hide: false,
    showText: ''
  },
  windowWidth: window.innerWidth
}

const getters = {
  layout (state) {
    return state.layout
  },
  mobileSidebar (state) {
    return state.mobileSidebar
  },
  windowWidth (state) {
    return state.windowWidth
  }
}

const actions = {
  clearLayout: ({ commit }) => {
    commit('SET_LAYOUT', residencyLayouts.SIMPLE_LAYOUT)
  },
  showMobileSidebar: ({ commit }) => {
    commit('SET_MOBILE_SIDEBAR', { hide: false })
  },
  hideMobileSidebar: ({ commit }, showText) => {
    commit('SET_MOBILE_SIDEBAR', { hide: true, showText })
  }
}

const mutations = {
  SET_LAYOUT (state, payload) {
    state.layout = payload
  },
  SET_MOBILE_SIDEBAR (state, sidebar) {
    if ('hide' in sidebar) {
      state.mobileSidebar.hide = sidebar.hide
    }
    if ('showText' in sidebar) {
      state.mobileSidebar.showText = sidebar.showText
    }
  },
  SET_WINDOW_WIDTH (state, width) {
    state.windowWidth = width
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
