import workshopLayouts from '@/workshop/consts/workshop-layouts.js'

const state = {
  layout: workshopLayouts.STANDARD_LAYOUT,
  windowWidth: window.innerWidth
}

const getters = {
  layout (state) {
    return state.layout
  },
  windowWidth (state) {
    return state.windowWidth
  }
}

const mutations = {
  SET_LAYOUT (state, payload) {
    state.layout = payload
  },
  SET_WINDOW_WIDTH (state, width) {
    state.windowWidth = width
  }
}

export default {
  state,
  getters,
  mutations
}
